import React from 'react';
import Flickity from 'react-flickity-component'

import Consulta from '../../Assets/Images/hands.png'
import Exames from '../../Assets/Images/doctor.png'

import Salu from '../../Assets/Images/salu.png'
import AmorSaude from '../../Assets/Images/amorsaude.png'
import DrConsulta from '../../Assets/Images/drconsulta.png'

import './styles.css'

export default function Needing({userData, token}) {

    const services = [
        {
            name: 'Consultas',
            description: 'Escolha a especialidade da consulta que você quer fazer. ' +
                'São mais de 60 especialidades a sua disposição',
            image: Consulta,
            url: 'consultas'
        },
        {
            name: 'Exames',
            description: 'Precisa de um exame? Escolha entre as mais de 1.000 opções disponíveis com desconto',
            image: Exames,
            url: 'exames'
        }
    ]

    const partners = [
        {name: 'Salú - Saúde aonde você estiver', logo: Salu, site: '#'},
        {name: 'Amor saúde', logo: AmorSaude, site: '#'},
        {name: 'Dr. Consulta', logo: DrConsulta, site: '#'},
    ]

    return(
        <div className={'needing_container'} id={'need'}>
            <div className={'needing_text_container'}>
                <h3 className={'needing_title'}>
                    O que você precisa ?
                </h3>
                <p className={'needing_description'}>
                    Encontre aqui a melhor opção para você.
                </p>
            </div>
            {(window.innerWidth <= 770) ? (
                <Flickity
                    className={'services_container'}
                    options={{
                        initialIndex: 0,
                        cellAlign: 'center',
                        wrapAround: false,
                        draggable: (window.innerWidth <= 770),
                        prevNextButtons: (window.innerWidth <= 770),
                        pageDots: false,

                    }}
                >
                    {
                        services.map((service, key) => (
                            <div key={key} className={'service_card'}>
                                <div className={'card_image'}>
                                    <img alt={''} className={'service_image'} src={service.image}/>
                                </div>
                                <div className={'card_text_container'}>
                                    <h4 className={'card_title'}>
                                        {service.name}
                                    </h4>
                                    <p className={'card_description'}>
                                        {service.description}
                                    </p>
                                </div>
                                <div
                                    className={'card_button'}
                                    style={service.url === '' ? {backgroundColor: '#dcdcdc'} : {}}
                                    onClick={() => service.url !== '' ?
                                        window.location.href = `${service.url}?token=${token}` : ''}
                                >
                                    {service.url !== '' ? 'Saiba mais' : 'Em breve'}
                                </div>
                            </div>
                        ))
                    }
                </Flickity>
            ) : (
                <div
                    className={'services_container'}
                    options={{
                        initialIndex: 0,
                        cellAlign: 'center',
                        wrapAround: (window.innerWidth <= 770),
                        draggable: (window.innerWidth <= 770),
                        prevNextButtons: (window.innerWidth <= 770),
                        pageDots: (window.innerWidth <= 770),

                    }}
                >
                    {
                        services.map((service, key) => (
                            <div key={key} className={'service_card'}>
                                <div className={'card_image'}>
                                    <img alt={''} className={'service_image'} src={service.image}/>
                                </div>
                                <div className={'card_text_container'}>
                                    <h4 className={'card_title'}>
                                        {service.name}
                                    </h4>
                                    <p className={'card_description'}>
                                        {service.description}
                                    </p>
                                </div>
                                <div
                                    className={'card_button'}
                                    style={service.url === '' ? {backgroundColor: '#dcdcdc'} : {}}
                                    onClick={() => service.url !== '' ?
                                        window.location.href = `${service.url}?token=${token}` : ''}
                                >
                                    {service.url !== '' ? 'Saiba mais' : 'Em breve'}
                                </div>
                            </div>
                        ))
                    }
                </div>
            )}
            <div className={'partners_container'} id={'partners'}>
                <div className={'partners_text_container'}>
                    <h5 className={'partner_title'}>
                        Parceiros
                    </h5>
                    <p className={'partner_description'}>
                        Veja abaixo os parceiros que você pode contar
                    </p>
                </div>
                <Flickity
                    className={'partner_images_container'}
                    options={{
                        initialIndex: (window.innerWidth <= 770) ? 0 : 1,
                        cellAlign: 'center',
                        wrapAround: (window.innerWidth <= 770),
                        draggable: (window.innerWidth <= 770),
                        prevNextButtons: (window.innerWidth <= 770),
                        pageDots: false,

                    }}
                >
                    {
                        partners.map((partner, key) => (
                            <div
                                key={key}
                                className={'images_content'}
                                // onClick={() => window.open(`${partner.site}`, '_blank')}
                            >
                                <div className={'partner_image'}>
                                    <img alt={''} src={partner.logo} />
                                </div>
                                <div className={'partner_name'}>
                                    <p>{partner.name}</p>
                                </div>
                            </div>
                        ))
                    }
                </Flickity>
            </div>
        </div>
    )
}
