import React from 'react';
import Header from "../Header";
import Footer from "../Footer";
import './style.css'

import Natura from '../../Assets/Images/natura.png'
import IdCard from '../../Assets/Icons/id-card.svg'

export default function AuthError() {
  return(
    <div className="auth-error">
      <img className="auth-error-logo" src={Natura} alt="" />
      <div className="auth-error-content">
        <img className="auth-error-icon" src={IdCard} alt="" />
        <p className="auth-error-message">
          Ops!
          Parece que voce não tem acesso a está página
        </p>
      </div>
      <div className="auth-error-footer">
        <Footer />
      </div>
    </div>
  )
}
