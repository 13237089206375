import React from "react";

import Banner from '../../Assets/Images/banner.png'

import './styles.css'

export default function Welcome() {

    const backgroundImageStyle = {
        backgroundImage: `url(${Banner})`
    }

    return(
        <div className={'welcome_container'} style={backgroundImageStyle} >
            <div className={'welcome_text_container'}>
                <h5 className={'welcome_title'}>
                    Bem vinda ao espaço Natura Saúde!
                </h5>
                <p className={'welcome_description'}>
                    Aqui você vai encontrar as melhores opções para cuidar da sua saúde e da sua família
                </p>
            </div>
        </div>
    )
}