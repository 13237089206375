import React from 'react';
import Header from '../Header';
import Welcome from '../Welcome';
import Greeting from '../Greeting';
import Needing from '../Needing';
import Footer from '../Footer';

export default function Home({userData, token}) {
    return(
        <div className={'page_container'}>
            <Header
                token={token}
                userData={userData}
            />
            <Welcome />
            <Greeting
                userData={userData}
            />
            <Needing
                token={token}
                userData={userData}
            />
            <Footer />
        </div>
    )
}