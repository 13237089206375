import React, { useEffect, useState } from 'react';
import {
    GrClose,
    FiMapPin,
    FaAngleDown
} from 'react-icons/all';
import Modal from 'react-modal'
import Loader from 'react-loader-spinner'
import {
    map,
    find,
    filter,
    sortBy,
    upperCase,
    findIndex,
    capitalize, lowerCase, reverse, isEmpty
} from 'lodash'
import jwt_decode from 'jwt-decode'

import {
    getUf,
    getCity,
    getServices,
    getLocations,
    getNaturaToken,
    getDicasMeiToken,
    requestPartnerUrl,
    getUserLocationData,
} from '../../services';
import TagManager from 'react-gtm-module';

import salu from '../../Assets/Images/salu.png'
import amorsaude from '../../Assets/Images/amorsaude.png'
import drconsulta from '../../Assets/Images/drconsulta.png'

import './styles.css'
import Header from '../Header';
import Footer from '../Footer';


// TYPE 1 = CONSULTAS
// TYPE 2 = EXAMES
export default function Service({ type, userData, token }) {

    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    }

    const [ufs, setUfs] = useState([])

    const [cities, setCities] = useState([])

    const [resultCounter, setResultCounter] = useState()

    const [userCity, setUserCity] = useState('')

    const [userState, setUserState] = useState('')

    const [allServices, setAllServices] = useState([])

    const [pendingCity, setPendingCity] = useState('')

    const [naturaToken, setNaturaToken] = useState(null)

    const [allLocations, setAllLocations] = useState([])

    const [pendingState, setPendingState] = useState('')

    const [dicasmeiToken, setDicasmeiToken] = useState(null)

    const [loadingMessage, setLoadingMessage] = useState('')

    const [selectedLocation, setSelectedLocation] = useState('')

    const [locationAllowed, setLocationAllowed] = useState(null)

    const [loadingServices, setLoadingServices] = useState(false)

    const [userPositionData, setUserPositionData] = useState(null)

    const [availableLocations, setAvailableLocations] = useState([])

    const [selectedSpeciality, setSelectedSpeciality] = useState('')

    const [showLocationModal, setShowLocationModal] = useState(false)

    const [selectedSpecialities, setSelectedSpecialities] = useState('')

    useEffect(() => {
        setLoadingMessage(
            `Por favor, permita o acesso a sua localização para que possamos identificar as 
          melhores opções de ${type === 1 ? 'consultas' : 'exames'} para você.`
        )
        getDicasMeiToken()
            .then(response => {
                setDicasmeiToken(response.data.token)
                getUf(response.data.token).then(response => {
                    const fixedUfNames = response.data.map(item => {
                        item.desnome = upperCase(item.desnome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                        return item
                    })
                    setUfs(sortBy(fixedUfNames, [function (o) {
                        return o.desnome
                    }]))
                })
            })
            .catch(error => console.log(error))
        getNaturaToken()
            .then(response => {
                setNaturaToken(response.data.token)
                getLocations(response.data.token)
                    .then(response => {
                        setAvailableLocations(filter(response.data.data, o => {
                            if (
                                o.cidade === 'Santos' ||
                                o.cidade === 'São Paulo' ||
                                o.cidade === 'Rio de Janeiro' ||
                                o.cidade === 'Belo Horizonte'
                            ) {
                                o.cidade = upperCase(o.cidade.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                                return o
                            }
                        }))
                    })
            })
            .catch(error => console.log(error))
        navigator.geolocation.getCurrentPosition(
            function (position) {
                getUserLocationData(`${position.coords.latitude},${position.coords.longitude}`, false)
                    .then(response => {
                        setLocationAllowed(true)
                        response.data.results[0].address_components.map(item => {
                            if (item.types.includes('administrative_area_level_1')) {
                                setUserState((item && item.long_name) ? item.long_name : '')
                            }
                            else if (item.types.includes('administrative_area_level_2')) {
                                setUserCity((item && item.long_name) ?
                                    upperCase(item.long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) : ''
                                )
                            }
                            return null
                        })
                        setUserPositionData({
                            ...response.data.results[0],
                            city: upperCase(response.data.results[0].address_components[0].short_name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                        })
                        setLoadingMessage(`Carregando ${type === 1 ? 'Consultas' : 'Exames'}...`)
                    })
            }, function () {
                getUserLocationData('', true)
                    .then(response => {
                        setLocationAllowed(false)
                        setUserState(response.data.region)
                        setUserPositionData({
                            ...response.data,
                            city: upperCase(response.data.city.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                        })
                        setUserCity(upperCase(response.data.city.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                        setLoadingMessage(`Carregando ${type === 1 ? 'Consultas' : 'Exames'}...`)
                    })
            })
    }, [])

    useEffect(() => {
        if (naturaToken) {
            getServicesJSON(naturaToken)
        }
    }, [naturaToken])

    useEffect(() => {
        if (pendingState !== '') {
            ufs.map(item => {
                if (item.desnome === pendingState) {
                    getCity(item.codigo, dicasmeiToken)
                        .then(response => {
                            const fixedCityName = response.data.map(item => {
                                item.desnome = upperCase(item.desnome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
                                return item
                            })
                            setCities(sortBy(fixedCityName, [function (o) {
                                return o.desnome
                            }]))
                        })
                }
                return null
            })
        }
    }, [pendingState, ufs])

    useEffect(() => {
        if (userState && userCity && allLocations && allLocations.length > 0) {
            const locations = []
            const userUf = find(ufs, ['desnome', upperCase(userState.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))])
            allLocations.map(o => {
                if (upperCase(o.cidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) === upperCase(userCity.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) {
                    o.showName = `${o.nome_curto} - ${o.cidade}`
                    locations.push(o)
                }
            })
            if (locations.length === 0 && userUf && userUf.dessigla) {
                allLocations.map(o => {
                    if (o.uf === userUf.dessigla) {
                        o.showName = `${o.nome_curto} - ${o.cidade}`
                        locations.push(o)
                    }
                })
            }
            if (locations && locations.length > 0) {
                setSelectedLocation(locations[0].id_unidade)
            }
        }
    }, [userState, userCity, allLocations])

    const getServicesJSON = async () => {
        let servicesResponse = []
        await getServices(naturaToken)
            .then(response => {
                servicesResponse = sortBy(filter(response.data, item => {
                    return item.procedimento === ((type === 1) ? 'CONSULTA' : 'EXAME')
                }), 'produto')
            })
        if (type === 1) {
            servicesResponse.unshift(servicesResponse.splice(findIndex(servicesResponse, ['produto', 'GINECOLOGIA/OBSTETRÍCIA']), 1)[0]);
            servicesResponse.unshift(servicesResponse.splice(findIndex(servicesResponse, ['produto', 'DERMATOLOGIA']), 1)[0]);
            servicesResponse.unshift(servicesResponse.splice(findIndex(servicesResponse, ['produto', 'CLINICO GERAL']), 1)[0]);
        }
        setAllServices(servicesResponse.map(item => {
            item.servicos = reverse(sortBy(item.servicos, ['parceiro']))
            return item
        }))
    }

    const handleSpecialitySelect = (service_key) => {
        setLoadingServices(true)
        setSelectedSpeciality(service_key)

        // loop through services and remove onsite services if user city is not SP, RH, BH, Santos
        let city = userCity === '' ? userPositionData.city : userCity
        let citiesWithOnSite = ['SAO PAULO', 'RIO DE JANEIRO', 'SANTOS', 'BELO HORIZONTE']
        if (!citiesWithOnSite.includes(city)) {
            allServices[service_key].servicos.forEach((service, index) => {
                if (service.parceiro == 'DRCONSULTA' && service.id_produto_presencial) {
                    delete service.id_produto_presencial
                }
            })
        }

        setSelectedSpecialities(allServices[service_key])
        let servicesCounter = 0
        allServices[service_key].servicos.map(service => {
            if (service.id_produto_presencial) {
                servicesCounter = servicesCounter + 1
            }
            if (service.id_produto_telemed) {
                servicesCounter = servicesCounter + 1
            }
        })
        const userUf = find(ufs, ['desnome', upperCase(userState.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))])
        TagManager.dataLayer({
            dataLayer: {
                event: 'busca',
                busca: {
                    produto: allServices[service_key].produto,
                    resultados: servicesCounter,
                    cidade: upperCase(userCity.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
                    estado: userUf ? userUf.dessigla : userState
                },
                acesso: null
            }
        })
        setResultCounter(servicesCounter)
        setLoadingServices(false)
        window.location.href = '#result'
    }

    const handlePartnerRequestService = (service, modalidade, id, speciality) => {
        if (token) {
            const data = jwt_decode(token)
            const userUf = find(ufs, ['desnome', upperCase(userState.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))])
            TagManager.dataLayer({
                dataLayer: {
                    event: 'acesso',
                    acesso: {
                        id: speciality.id,
                        produto: speciality.produto,
                        parceiro: service.parceiro,
                        modalidade: modalidade,
                        cidade: upperCase(userCity.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
                        estado: userUf ? userUf.dessigla : userState
                    }
                }
            })
            requestPartnerUrl(lowerCase(service.parceiro), {
                'TOKEN': token,
                'ID_PRODUTO': speciality.id,
                'MODALIDADE': modalidade,
                'EMAIL': data.email || '',
                'ID_PRODUTO_PARCEIRO': id,
                'USER_ID': data.external_user_id,
                'NOME': data.name || data.nome || '',
                'UF': userUf ? userUf.dessigla : userState,
                'CIDADE': upperCase(userCity.normalize('NFD').replace(/[\u0300-\u036f]/g, '')),
                'UNIDADE': (modalidade === 'Presencial' &&
                    service.parceiro === 'DRCONSULTA' && selectedLocation) ? selectedLocation : '',
            }, naturaToken)
                .then(response => {
                    window.open(response.data.url, '_blank')
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }

    return (
        <div>
            <Header
                token={token}
                userData={userData}
            />
            {(
                userPositionData && allServices && allServices.length > 0
                && userCity !== '' && userState !== ''
            ) && (
                    <div>
                        <div className={'exams_container'}>
                            <div className={'exams_text_container'}>
                                <h5 className={'exams_title'}>
                                    {type === 1 ? 'Consultas' : 'Exames'}
                                </h5>
                                <p className={'exams_description'}>
                                    Você está em:  Home / {type === 1 ? 'Consultas' : 'Exames'}
                                </p>
                            </div>
                            <div className={'exams_service'}>
                                <div className={'exams_user_container'}>
                                    <p className={'exams_info_text'}>
                                        {'Você está em: '}
                                    </p>
                                    <div className={'exams_location_container'}>
                                        <FiMapPin className={'exams_location_icon'} />
                                        <p className={'exams_location_text'}>
                                            {
                                                `${capitalize(userCity === '' ? userPositionData.city : userCity)} 
                                        - 
                                        ${capitalize(userState === '' ? userPositionData.region : userState)}`
                                            }
                                        </p>
                                    </div>
                                    <p
                                        className={'exams_location_change'}
                                        onClick={() => setShowLocationModal(true)}
                                    >
                                        Alterar
                                    </p>
                                </div>
                                <div className={'exams_location_bar'} />
                            </div>
                        </div>
                        <div className={'exams_service_container'}>
                            <p className={'exams_service_text'}>
                                Escolha abaixo qual será {type === 1 ? 'sua consulta' : ' seu exame'}
                            </p>
                            <select
                                className={'exams_service_select'}
                                value={!isEmpty(selectedSpecialities) ? selectedSpeciality : 'Clique aqui'}
                                style={{ backgroundImage: `url(${FaAngleDown})` }}
                                onChange={(e) => handleSpecialitySelect(e.target.value)}
                            >
                                <option disabled style={{ display: 'none' }}>
                                    Clique aqui
                                </option>
                                {map(allServices, (speciality, key) => (
                                    <>
                                        <option
                                            key={key}
                                            value={key}
                                        >
                                            {speciality.produto}
                                        </option>
                                        {(type === 1 && key === 2) && (
                                            <option disabled>────────────────────</option>
                                        )}
                                    </>
                                ))}
                            </select>
                        </div>
                        <div className={'exams_services_results'} id={'result'}>
                            {(!isEmpty(selectedSpecialities) && loadingServices) && (
                                <Loader
                                    width={100}
                                    height={100}
                                    color="#f5915f"
                                    type={'TailSpin'}
                                    style={{ textAlign: 'center', paddingTop: '2em', marginBottom: '10em' }}
                                />
                            )}
                            {(!isEmpty(selectedSpecialities) && selectedSpecialities.servicos.length > 0 && !loadingServices) && (
                                <div className={'exams_result_list_container'}>
                                    <div className={'exams_result_count_container'}>
                                        <p>
                                            Temos {resultCounter} resultados para você
                                        </p>
                                    </div>
                                    {(!isEmpty(selectedSpecialities) && selectedSpecialities.servicos.length > 0) &&
                                        selectedSpecialities.servicos.map((item, key) => (
                                            <>
                                                {(((item.parceiro === 'DRCONSULTA' && availableLocations.length > 0 && item.id_produto_presencial)
                                                ) || (item.parceiro !== 'DRCONSULTA' && item.id_produto_presencial)) && (
                                                        <div className={'exams_result_list_item'} key={key}>
                                                            <div className={'exams_result_list_item_logo_container'}>
                                                                <img className={'exams_result_item_logo'} src={
                                                                    item.parceiro === 'SALU' ?
                                                                        salu : item.parceiro === 'DRCONSULTA' ?
                                                                            drconsulta : amorsaude
                                                                } />
                                                                <div className={'exams_result_item_type'}>
                                                                    <p className={'exams_result_item_type_label'}>
                                                                        Presencial
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={'exams_result_item_description_container'}
                                                            >
                                                                <p className={'exams_result_item_description_text'}>
                                                                    {(type === 1) ?
                                                                        (item.parceiro === 'DRCONSULTA' ?
                                                                            'Seja atendida até no mesmo dia por excelentes ' +
                                                                            'médicos e sem custo de retorno por 30 dias por ' :
                                                                            'Sua consulta por ') :
                                                                        (item.parceiro === 'DRCONSULTA') ?
                                                                            ('Seu exame com 15% de desconto e feito nos ' +
                                                                                'melhores equipamentos do mundo.') :
                                                                            ('Seu exame a partir de 15%')
                                                                    }
                                                                    <b>
                                                                        {
                                                                            (type === 1) ?
                                                                                (`R$ ${item.preco_natura_presencial}`) :
                                                                                ('')
                                                                        }
                                                                    </b>
                                                                    {(type === 1 && item.parceiro === 'DRCONSULTA') ? (
                                                                        ''
                                                                    ) : (item.parceiro === 'DRCONSULTA') ? ('') :
                                                                        (type === 1 && item.parceiro === 'AMORSAUDE') ? ('. ' +
                                                                            'Para agendar ligue 4003-5783 e informe o código ' +
                                                                            'SOU CONSULTORA NATURA.') : ('')}
                                                                </p>
                                                            </div>
                                                            <div className={'exams_result_item_button_container'}>
                                                                {(item.parceiro !== 'AMORSAUDE') && (
                                                                    <button
                                                                        className={'exams_result_item_button'}
                                                                        onClick={
                                                                            (e) => {
                                                                                e.preventDefault()
                                                                                handlePartnerRequestService(
                                                                                    item,
                                                                                    'PRESENCIAL',
                                                                                    item.id_produto_presencial,
                                                                                    selectedSpecialities
                                                                                )
                                                                            }
                                                                        }
                                                                    >
                                                                        <p className={'exams_result_item_button_text'}>
                                                                            Agendar
                                                                        </p>
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                {(item.id_produto_telemed) && (
                                                    <div className={'exams_result_list_item'}>
                                                        <div className={'exams_result_list_item_logo_container'}>
                                                            <img className={'exams_result_item_logo'} src={
                                                                item.parceiro === 'SALU' ?
                                                                    salu : item.parceiro === 'DRCONSULTA' ?
                                                                        drconsulta : amorsaude
                                                            } />
                                                            <div className={'exams_result_item_type'}>
                                                                <p className={'exams_result_item_type_label'}>
                                                                    Médicos por vídeo
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className={'exams_result_item_description_container'}>
                                                            <p className={'exams_result_item_description_text'}>
                                                                {(item.parceiro === 'SALU') && (
                                                                    <>
                                                                        {'Consultas ilimitadas, válidas por 30 dias após o pagamento. '}
                                                                        <b>
                                                                            {`R$ ${item.preco_natura_teleconsulta}`}
                                                                        </b>
                                                                        <br />
                                                                        {'Atendimento sem sair de casa, a qualquer hora do dia e da semana'}
                                                                    </>
                                                                )}
                                                                {(item.parceiro !== 'SALU') && (
                                                                    <>
                                                                        {(type === 1) ? (
                                                                            'Atendimento por vídeo. Sua consulta sem sair de casa por '
                                                                        ) : (
                                                                            'Seu exame por '
                                                                        )}
                                                                        <b>
                                                                            {`R$ ${item.preco_natura_teleconsulta}`}
                                                                        </b>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className={'exams_result_item_button_container'}>
                                                            <button
                                                                className={'exams_result_item_button'}
                                                                onClick={
                                                                    (e) => {
                                                                        e.preventDefault()
                                                                        handlePartnerRequestService(
                                                                            item,
                                                                            'TELEMED',
                                                                            item.id_produto_telemed,
                                                                            selectedSpecialities
                                                                        )
                                                                    }
                                                                }
                                                            >
                                                                <p className={'exams_result_item_button_text'}>
                                                                    Agendar
                                                                </p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                )}
            {(
                loadingMessage !== '' && userCity === '' && userState === ''
            ) && (
                    <div className={'exams_container'}>
                        <h3
                            className={'loading_info_text'}
                        >
                            {loadingMessage}
                        </h3>
                        <Loader
                            style={{ textAlign: 'center', paddingTop: '10em', paddingBottom: '10em' }}
                            type={'TailSpin'}
                            color="#f5915f"
                            height={100}
                            width={100}
                        />
                    </div>
                )}
            <Modal
                isOpen={showLocationModal}
                onRequestClose={() => setShowLocationModal(false)}
                style={modalStyle}
            >
                <GrClose
                    className={'location_modal_close_icon'}
                    onClick={() => setShowLocationModal(false)}
                />
                <div className={'location_modal_container'}>
                    <h4 className={'location_modal_title'}>
                        Localização
                    </h4>
                    <p className={'location_modal_description'}>
                        Selecione estado e cidade
                    </p>
                </div>
                <label className={'location_modal_select_label'}>
                    Estado
                    <select
                        value={pendingState !== '' ? pendingState : ufs.length <= 1 ? 'Carregando...' : 'Clique aqui'}
                        className={'location_modal_select'}
                        onChange={(e) => {
                            setPendingState(e.target.value)
                        }}
                    >
                        <option style={(ufs.length <= 1) ? {} : { display: 'none' }}>
                            {ufs.length <= 1 ? 'Carregando...' : 'Clique aqui'}
                        </option>
                        {(ufs.length >= 1) && ufs.map((item, key) => (
                            <option
                                className={'location_modal_select_label'}
                                key={key}
                            >
                                {item.desnome}
                            </option>
                        ))}
                    </select>
                </label>
                <label className={'location_modal_select_label'}>
                    Cidade
                    <select
                        value={
                            pendingState === '' ? 'Selecione um estado' :
                                pendingCity !== '' ? pendingCity : cities.length <= 1 ?
                                    'Carregando...' : 'Clique aqui'
                        }
                        className={'location_modal_select'}
                        onChange={(e) => {
                            setPendingCity(e.target.value)
                        }}
                    >
                        <option style={(cities.length <= 1) ? {} : { display: 'none' }}>
                            {
                                userState === '' ? 'Selecione um estado' :
                                    cities.length <= 1 ? 'Carregando...' : 'Clique aqui'
                            }
                        </option>
                        {(cities.length >= 1) && cities.map((item, key) => (
                            <option
                                className={'location_modal_select_label'}
                                key={key}
                            >
                                {item.desnome}
                            </option>
                        ))}
                    </select>
                </label>
                <div className={'location_modal_buttons_container'}>
                    <button
                        className={'location_modal_button_ghost'}
                        onClick={() => setShowLocationModal(false)}
                    >
                        Cancelar
                    </button>
                    <button
                        className={'location_modal_button'}
                        onClick={() => {
                            setUserCity(upperCase(pendingCity.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                            setUserState(pendingState)
                            if (selectedSpeciality) {
                                handleSpecialitySelect(selectedSpeciality)
                            }
                            setShowLocationModal(false)
                        }}
                    >
                        Confirmar
                    </button>
                </div>
            </Modal>
            <Modal
                isOpen={locationAllowed === false}
                style={modalStyle}
            >
                <div className={'location_modal_container'}>
                    <h4 className={'location_modal_title'}>
                        Localização
                    </h4>
                    <p className={'location_modal_description'}>
                        Selecione estado e cidade
                    </p>
                </div>
                <label className={'location_modal_select_label'}>
                    Estado
                    <select
                        value={pendingState !== '' ? pendingState : ufs.length <= 1 ? 'Carregando...' : 'Clique aqui'}
                        className={'location_modal_select'}
                        onChange={(e) => {
                            setPendingState(e.target.value)
                        }}
                    >
                        <option style={(ufs.length <= 1) ? {} : { display: 'none' }}>
                            {ufs.length <= 1 ? 'Carregando...' : 'Clique aqui'}
                        </option>
                        {(ufs.length >= 1) && ufs.map((item, key) => (
                            <option
                                className={'location_modal_select_label'}
                                key={key}
                            >
                                {item.desnome}
                            </option>
                        ))}
                    </select>
                </label>
                <label className={'location_modal_select_label'}>
                    Cidade
                    <select
                        value={
                            pendingState === '' ? 'Selecione um estado' :
                                pendingCity !== '' ? pendingCity : cities.length <= 1 ?
                                    'Carregando...' : 'Clique aqui'
                        }
                        className={'location_modal_select'}
                        onChange={(e) => {
                            setPendingCity(e.target.value)
                        }}
                    >
                        <option style={(cities.length <= 1) ? {} : { display: 'none' }}>
                            {
                                userState === '' ? 'Selecione um estado' :
                                    cities.length <= 1 ? 'Carregando...' : 'Clique aqui'
                            }
                        </option>
                        {(cities.length >= 1) && cities.map((item, key) => (
                            <option
                                className={'location_modal_select_label'}
                                key={key}
                            >
                                {item.desnome}
                            </option>
                        ))}
                    </select>
                </label>
                <div className={'location_modal_buttons_container'}>
                    <button
                        className={'location_modal_button'}
                        onClick={() => {
                            setUserCity(upperCase(pendingCity.normalize('NFD').replace(/[\u0300-\u036f]/g, '')))
                            setUserState(pendingState)
                            if (selectedSpeciality) {
                                handleSpecialitySelect(selectedSpeciality)
                            }
                            setLocationAllowed(true)
                        }}
                    >
                        Confirmar
                    </button>
                </div>
            </Modal>
            <Footer />
        </div>
    )
}
