import React, {useState} from "react";
import {
    FaBars,
    FaChevronUp,
    FaChevronDown
} from "react-icons/all";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import natura_logo from '../../Assets/Images/natura.png'

import './styles.css'

export default function Header({userData, token}) {

    const [showMenu, setShowMenu] = useState(false)

    const [showSubMenu, setShowSubMenu] = useState(false)

    const handleCloseMenus = () => {
        setShowMenu(false)
        setShowSubMenu(false)
    }

    return(
        <div className={'header_container'}>
            <Link
                onClick={() => handleCloseMenus()}
                className={'header_logo'}
                to={`/?token=${token}`}
            >
                <img alt={''} className={'natura_logo'} src={natura_logo} />
            </Link>
            <div className={'header_menu'}>
                <HashLink
                    to={`/?token=${token}#need`}
                    className={'menu_item drop_down_item'}
                >
                    o que você precisa?
                </HashLink>
                <HashLink
                    to={`/?token=${token}#partners`}
                    className={'menu_item'}
                >
                    parceiros
                </HashLink>
                <a
                    target={'_blank'}
                    className={'menu_item'}
                    href={'https://natura.dicasmei.com.br/duvidas_natura.pdf'}
                >
                    dúvidas
                </a>
            </div>

            {/*MENU MOBILE*/}
            <div className={'mobile_header'}>
                <div className="topnav">
                    <Link
                        onClick={() => handleCloseMenus()}
                        className={'mobile_header_logos'}
                        to={`/?token=${token}`}
                        style={{margin: 'auto auto auto 0', padding: 'unset'}}
                    >
                        <img alt={''} className={'natura_logo_mobile'} src={natura_logo} />
                    </Link>
                    {(showMenu) && (
                        <div className={"topnav_itens"}>
                            <HashLink
                                to={`/?token=${token}#need`}
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                o que você precisa?
                            </HashLink>
                            <HashLink
                                to={`/?token=${token}#partners`}
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                parceiros
                            </HashLink>
                            <a
                                target={'_blank'}
                                href={'https://natura.dicasmei.com.br/duvidas_natura.pdf'}
                            >
                                dúvidas
                            </a>
                        </div>
                    )}
                    <FaBars
                        className={'mobile_header_menu'}
                        onClick={() => setShowMenu(!showMenu)}
                    />
                </div>
            </div>
        </div>
    )
}