import React from "react";

import Hearts from '../../Assets/Icons/coração.svg'

import './styles.css'

export default function Greeting({userData}) {
    return (
        <div className={'greeting_container'}>
            <div className={'greeting_icon'}>
                <img alt={''} className={'greeting_heart'} src={Hearts} />
            </div>
            <div className={'greeting_text_container'}>
                <div className={'greeting_title_container'}>
                    <h3 className={'greeting_title'}>
                        Um benefício para que você possa cuidar da sua saúde e de sua família
                    </h3>
                </div>
                <p className={'greeting_description'}>
                    O Natura Saúde oferece para a rede de consultoras descontos nos mais diversos serviços de saúde.
                    Aqui você poderá comparar os diferenciais e valores de consultas,
                    exames e outros serviços de saúde e escolher aquele que melhor atende a sua necessidade.
                </p>
            </div>
        </div>
    )
}