import Axios from 'axios';

const getApiUrl = (endpoint) => {
    return `https://www.dicasmei.com.br/api/${endpoint}`;
}

const getNaturaApiUrl = (endpoint) => {
    // return `https://dev.k8s.natura.dicasmei.com.br/api/${endpoint}`;
    return `https://k8s.natura.dicasmei.com.br/api/${endpoint}`;
}

export function salu_json() {
    return [
        { nome: 'cardiologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'clínica geral', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'dermatologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'endocrinologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'hematologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'infectologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'neurologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'oftalmologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'ortopedia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'pediatria', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'pneumologia', grupo: 'CM', TELEMEDICINA: 1 },
        { nome: 'psiquiatria', grupo: 'CM', TELEMEDICINA: 1 },
    ]
}

export async function dr_consulta_json(token) {
    let responseReturn = []
    await Axios.get(getNaturaApiUrl('beneficio/saude/drconsulta/produtos'), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        responseReturn = response.data.data
    })
        .catch(error => {
            console.log(error)
        })
    return responseReturn
}

export async function amor_saude_json(token) {
    let responseReturn = []
    await Axios.get(getNaturaApiUrl('beneficio/saude/amorsaude/produtos'), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }).then(response => {
        responseReturn = response.data.content
    })
        .catch(error => {
            console.log(error)
        })

    return responseReturn
}

export function getNaturaToken() {
    // return Axios.post(getNaturaApiUrl('login_check'), {
    //     'username': 'natura_user',
    //     'password': 'natura_user'
    // })
    return Axios.post(getNaturaApiUrl('login_check'), {
        'username': 'natura_user',
        'password': 'b6qD4dwQJb6Ltbte'
    })
}

export function getDicasMeiToken() {
    return Axios.post(getApiUrl('login_check'), {
        username: 'api_user',
        password: 'pZ25ShU2DRo3gxYf'
    })
}

export function getSpecialities() {
    return Axios.get('https://api.salu.digital/store/open/specialties/')
}

export function getUserLocationData(location, byIp) {
    if (byIp) {
        return Axios.get('https://ip.zxq.co/')
    } else {
        return Axios.get(
            'https://maps.googleapis.com/maps/api/geocode/json?' +
            `latlng=${location}` +
            '&key=AIzaSyDhVfkZ1H45lZVF4LNgu8QvQZiO_bmtNvM'
        )
    }
}

export function getUf(token) {
    return Axios.get(getApiUrl('endereco/estado'), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getCity(uf, token) {
    return Axios.get(getApiUrl(`endereco/estado/${uf}/cidades`), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export function requestPartnerUrl(partner, data, token) {
    return Axios.post(getNaturaApiUrl(`beneficio/saude/${partner}/url`), data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getServices(token) {
    return Axios.get(getNaturaApiUrl('beneficio/saude/produtos'), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export function getLocations(token) {
    return Axios.get(getNaturaApiUrl('beneficio/saude/drconsulta/listaunidades'), {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}
