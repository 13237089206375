import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'flickity/dist/flickity.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

ReactGA.initialize('UA-112487562-10', {
    gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
    }
});


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
